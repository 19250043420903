import React from "react";
import "./ContactSection.css";
import JoinButton from "../../custom-buttons/join-button/JoinButton";
import Footer from "../../footer/Footer";
import Contact from "../../contact/Contact";
import Socials from "../../socials/Socials";
import HomeButton from "../../custom-buttons/home-button/HomeButton";

const ContactSection = () => {
  return (
    <div className="contact-section-box" id="contact">
      <div className="contact-inner-box">
        <JoinButton />

        <div className="contact-holder">
          <Contact />
          <Socials />
          <HomeButton />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactSection;
