import React, { useState } from "react";
import "./SignButton.css";

const SignButton = () => {
  const [hovered, setHovered] = useState(false);

  const handleClick = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSedBJTUGrRl0PwaJQjg8JglsZiHBMuYMUmubYPy7jh8NKI5hQ/viewform",
      "_blank"
    );
  };
  return (
    <div
      className="sign-button-box"
      onClick={handleClick}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <div className={`sign-button simple ${!hovered ? "active" : ""}`}></div>
      <div className={`sign-button hovered ${hovered ? "active" : ""}`}></div>
    </div>
  );
};

export default SignButton;
