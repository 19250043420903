import React from "react";
import { socials } from "../../constant/socials";
import "./Socials.css";
const Socials = () => {
  const handleOpenLink = (link?: string) => {
    if (!link) {
      return;
    }
    window.open(link, "_blank");
  };
  return (
    <div className="social-container">
      {socials.map((socialItem, key) => (
        <img
          key={key}
          src={socialItem.icon}
          alt=""
          onClick={() => {
            handleOpenLink(socialItem.link);
          }}
          style={{
            cursor: socialItem.link ? "pointer" : "auto",
          }}
        />
      ))}
    </div>
  );
};

export default Socials;
