import { SocialItem } from "../types/social.type";
import youtubeIcon from "../assets/icons/social/youtube.svg";
import reditIcon from "../assets/icons/social/redit.svg";
import twitterIcon from "../assets/icons/social/twitter.svg";

export const socials: SocialItem[] = [
  {
    link: "https://www.youtube.com/@ArchitectsOfFateCCG-yu7we",
    icon: youtubeIcon,
  },
  {
    icon: twitterIcon,
    link: "https://x.com/AoF_ccg",
  },
  {
    icon: reditIcon,
    link: "https://www.reddit.com/r/ArchitectsOfFate/?type=TEXT",
  },
];
