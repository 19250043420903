import React, { useState } from "react";
import { headerItems } from "../../../constant/header";
import { useScrollToElement } from "../../../hooks/useScrollToElement";
import { HeaderItemModel } from "../../../types/header.type";
import lozengeIcon from "../../../assets/icons/common/lozenge.svg";
import "./MobileHeader.css";

const MobileHeader = () => {
  const [clickedElement, setClickedElement] = useState("");
  const [menuOpen, setMenuOpen] = useState(false); // Manage menu open/close state

  const handleClick = useScrollToElement();

  const handleOnClick = (item: HeaderItemModel) => {
    if (item.scrollToId) handleClick(item.scrollToId);
    setClickedElement(item.hoverText);
    setMenuOpen(false); // Close menu after clicking an item
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle menu on hamburger click
  };

  return (
    <div className="mobile-header">
      <div className="hamburger-icon" onClick={toggleMenu}>
        {/* Hamburger icon */}
        <span className={`bar ${menuOpen ? "open" : ""}`}></span>
        <span className={`bar ${menuOpen ? "open" : ""}`}></span>
        <span className={`bar ${menuOpen ? "open" : ""}`}></span>
      </div>
      {menuOpen && ( // Conditionally display the menu when it is open
        <div className="mobile-menu">
          {headerItems.map((item) => (
            <div className="mobile-menu-item" key={item.hoverText}>
              <img src={lozengeIcon} alt="lozenge icon" />
              <p
                onClick={() => {
                  handleOnClick(item);
                }}
                className={`header-label ${
                  item.hoverText === clickedElement ? "active" : ""
                }`}
              >
                {item.hoverText}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
