import React, { useState } from "react";
import "./JoinButton.css";

const JoinButton = () => {
  const [hovered, setHovered] = useState(false);

  const onClick = () => {
    window.open("https://discord.gg/Yf5uvhwv86", "_blank");
  };

  return (
    <div
      className="join-button-box"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onClick={onClick}
    >
      <div className={`join-button simple ${!hovered ? "active" : ""}`}></div>
      <div className={`join-button hovered ${hovered ? "active" : ""}`}></div>
    </div>
  );
};

export default JoinButton;
