import { HeaderItemModel } from "../types/header.type";
import aboutIcon from "../assets/icons/header/Abouticon.svg";
import videoIcon from "../assets/icons/header/Videoicon.svg";
import contactIcon from "../assets/icons/header/Contacticon.svg";

export const headerItems: HeaderItemModel[] = [
  {
    iconUrl: aboutIcon,
    hoverText: "About",
    scrollToId: "about",
  },
  {
    iconUrl: videoIcon,
    hoverText: "Video",
    scrollToId: "video",
  },
  {
    iconUrl: contactIcon,
    hoverText: "Contact",
    scrollToId: "contact",
  },
];
