import React from "react";
import GradientBox from "../../gradient-box/GradientBox";
import "./VideoSection.css";
import lineIcon from "../../../assets/icons/lines/decorative-line2.svg";

const VideoSection = () => {
  return (
    <GradientBox id="video">
      <div className="video-section-box">
        <div className="outer-border">
          <div className="inner-border">
            <iframe
              src="https://www.youtube.com/embed/Z-GUdF870qc?si=5lGsiwhfx-H-0FY5"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <img className="video-section-line" src={lineIcon} alt="" />
      </div>
    </GradientBox>
  );
};

export default VideoSection;
