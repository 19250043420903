import { headerItems } from "../../constant/header";
import HeaderItem from "./header-item/HeaderItem";
import "./Header.css";
import MobileHeader from "./mobile-header/MobileHeader";

const Header = () => {
  return (
    <div className="header-container">
      <div className="desktop-menu">
        {headerItems.map((item, key) => (
          <HeaderItem headerItem={item} key={key} />
        ))}
      </div>
      <MobileHeader />
    </div>
  );
};

export default Header;
