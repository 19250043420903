import React, { FC, useState } from "react";
import { HeaderItemModel } from "../../../types/header.type";
import lozengeIcon from "../../../assets/icons/common/lozenge.svg";

import "./HeaderItem.css";
import { useScrollToElement } from "../../../hooks/useScrollToElement";

type Props = {
  headerItem: HeaderItemModel;
};

const HeaderItem: FC<Props> = ({ headerItem }) => {
  const [hovered, setHovered] = useState(false);
  const handleClick = useScrollToElement();

  return (
    <div
      className="header-item"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        if (headerItem.scrollToId) handleClick(headerItem.scrollToId);
      }}
    >
      <img
        className="header-item-icon"
        src={headerItem.iconUrl}
        alt={headerItem.hoverText}
      />
      <div className={`header-item-hovered ${hovered ? "active" : ""}`}>
        <img src={lozengeIcon} alt="&#9674;" />
        <p>{headerItem.hoverText}</p>
      </div>
    </div>
  );
};

export default HeaderItem;
