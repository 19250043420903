import footerLine from "../../assets/icons/lines/footer-line.svg";
import architectImage from "../../assets/images/architect.png";
import "./Footer.css";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer-box">
      <div className="footer-logo-img">
        <img alt={"AOF"} src={architectImage} />
      </div>
      <div className="footer-gray">
        <img className="" src={footerLine} alt="" />
        <h3>{currentYear}</h3>
      </div>
    </div>
  );
};

export default Footer;
