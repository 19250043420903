import "./App.css";
import CardSection from "./components/sections/card-section/CardSection";
import ContactSection from "./components/sections/contact-section/ContactSection";
import LogoSection from "./components/sections/logo-section/LogoSection";
import VideoSection from "./components/sections/video-section/VideoSection";
import WelcomeSection from "./components/sections/welcome-section/WelcomeSection";

function App() {
  return (
    <>
      <LogoSection />
      <WelcomeSection />
      <CardSection />
      <VideoSection />
      <ContactSection />
    </>
  );
}

export default App;
