import React from "react";
import lozenge from "../../assets/icons/common/lozenge.svg";
import contactImg from "../../assets/images/contact.png";
import "./Contact.css";

const Contact = () => {
  return (
    <a href="mailto:info@architectoffate.com" className="email-contact">
      <img src={contactImg} alt="" />
    </a>
  );
};

export default Contact;
