import { useCallback } from "react";

export const useScrollToElement = () => {
  const headerHeight = 50;

  const handleClick = useCallback((id: string) => {
    const targetElement = document.getElementById(id);

    if (!targetElement) {
      console.error(`Element with id ${id} not found.`);

      // If not found and not on root ("/"), navigate to "/#id"
      if (window.location.pathname !== "/") {
        window.location.href = `/#${id}`;
      }

      return;
    }

    const elementPosition = targetElement.getBoundingClientRect().top;
    // Check if the current URL is the root ("/")
    const isRoot = window.location.pathname === "/";

    window.scrollBy({
      top: elementPosition,
      behavior: "smooth",
    });

    // If not on the root, navigate to "/#id"
    if (!isRoot) {
      window.location.href = `/#${id}`;
    }
  }, []);

  return handleClick;
};
