import React, { FC, ReactNode } from "react";
import "./GradientBox.css";
import gradientBg from "../../assets/images/backgorunds/gradient-bg.jpg";

type Props = {
  children: ReactNode;
  id?: string;
};

const GradientBox: FC<Props> = ({ children, id }) => {
  return (
    <div
      id={id}
      className="gradient-backround-box"
      style={{
        backgroundImage: `url(${gradientBg})`,
      }}
    >
      {/* <div className="gradient-backround-box"> */}
      {children}
      {/* </div> */}
    </div>
  );
};

export default GradientBox;
