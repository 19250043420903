import React, { Suspense } from "react";
import Spinner from "../../spinner/Spinner";
import "./CardSection.css";

const Spline = React.lazy(() => import("@splinetool/react-spline"));

const CardSection = () => {
  // const [activeIndex, setActiveIndex] = useState(0);
  // const [disableClick, setDisableClick] = useState(true);

  // const handleIncrease = () => {
  //   if (disableClick) return;
  //   setDisableClick(true);
  //   activeIndex === splineLinks.length - 1
  //     ? setActiveIndex(0)
  //     : setActiveIndex((prev) => prev + 1);
  // };

  // const handleDecrease = () => {
  //   if (disableClick) return;
  //   setDisableClick(true);
  //   activeIndex === 0
  //     ? setActiveIndex(splineLinks.length - 1)
  //     : setActiveIndex((prev) => prev - 1);
  // };

  return (
    <div className="card-section-box">
      <div className="card-gradient">
        <Suspense fallback={<Spinner />}>
          <Spline
            scene={
              "https://prod.spline.design/vPOH3t9-cSivS5MY/scene.splinecode"
            }
            className="spline"
          />
        </Suspense>
      </div>
    </div>
  );
};

export default CardSection;
