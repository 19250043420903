import React from "react";
import GradientBox from "../../gradient-box/GradientBox";
import "./WelcomeSection.css";

import decorativeLine from "../../../assets/icons/lines/decorative-line.svg";
import SignButton from "../../custom-buttons/sign-button/SignButton";
const WelcomeSection = () => {
  return (
    <GradientBox id="about">
      <div className="welcome-section-box">
        <div className="title-container">
          <h1>Welcome to Architects of Fate,</h1>
          <h2>where mythology is in your hands.</h2>
        </div>
        <div className="description-container">
          <p>
            Control the destinies of legendary heroes and gods reshaping their
            fates with each card. Will you alter their stories and forge new
            legends? The power to change the myth lies with you.
          </p>
          <p>
            Outsmart your opponents by making tactical decisions and lead your
            team of five Paragons to victory across all three lanes.
          </p>
        </div>

        <SignButton />

        <img className="welcome-decorative-line" src={decorativeLine} alt="" />
      </div>
    </GradientBox>
  );
};

export default WelcomeSection;
