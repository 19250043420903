import React, { Suspense } from "react";
import "./logo-section.css";
import Header from "../../header/Header";
import Spinner from "../../spinner/Spinner";

const Spline = React.lazy(() => import("@splinetool/react-spline"));

const LogoSection = () => {
  return (
    <div className="logo-section-box">
      <div className="logo-gradient">
        <Header />
        <Suspense fallback={<Spinner />}>
          <Spline
            onLoad={() => {
              console.log("asdasd ad asd as");
            }}
            scene="https://prod.spline.design/JvMCde22ecor4xOj/scene.splinecode"
            className="spline"
          />
        </Suspense>
      </div>
    </div>
  );
};

export default LogoSection;
