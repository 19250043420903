import React, { useState } from "react";
import homeButtonImage from "../../../assets/images/buttons/home-button.png";
import lozengeIcon from "../../../assets/icons/common/lozenge.svg";
import "./HomeButton.css";
const HomeButton = () => {
  const [hovered, sethovered] = useState(false);
  const handleClick = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };
  return (
    <div
      className="home-button"
      onClick={handleClick}
      onMouseEnter={() => {
        sethovered(true);
      }}
      onMouseLeave={() => {
        sethovered(false);
      }}
    >
      <img src={homeButtonImage} alt="" />
      <div className={`home-button-hovered ${hovered ? "active" : ""}`}>
        <img src={lozengeIcon} alt="" />
        <p>Home</p>
      </div>
    </div>
  );
};

export default HomeButton;
